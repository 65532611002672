<template>
  <div class="saved_persona_header">
    <img class="persona_avatar" alt="persona" :src="`/gfx/avatars/${avatarImg}.svg`" >
    <h1>Din persona "{{ personaName }}" er klar</h1>
    <p>
      Vi håber at du synes om dette værktøj, og vil få meget glæde af det.
    </p>
  </div>
  <div class="get_persona_row">
    <div class="get_persona_col">
      <div class="get_persona_block">
        <Card>
          <template #title>
            Del Din Persona
          </template>
          <template #content>
            <p>På nedenstående link kan du og andre se din gemte persona.</p>
            <InputText ref="inputPersonaUrl" :value="savedPersonaUrl" @click="copyPersonaUrl" v-tooltip.bottom.focus="'Link er nu kopieret'"></InputText>
            <p><i>Click på boksen for at kopiere</i></p>
            <p><a></a></p>
          </template>
        </Card>
      </div>
    </div>
    <div class="get_persona_col">
      <div class="get_persona_block">
        <Card>
          <template #title>
            Download Din Persona
          </template>
          <template #content>
            <p>Gem en printvenlig kopi af din persona til din computer.</p>
            <Button label="Download PDF" @click="generatePdf"/>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, ref, onMounted} from "vue";
import store from "@/store/store";
import jspdf from "jspdf";

export default {
  name: "SavedPersona",
  setup() {

    const inputPersonaUrl = ref(null)

    onMounted( () => {
      // inputPersonaUrl.value.$el.select()
      // document.execCommand('copy')
    })

    function copyPersonaUrl(){
      inputPersonaUrl.value.$el.select()
      document.execCommand('copy')
    }

    const avatarImg = computed( () => {
      if(store.state.avatar){
        return store.state.avatar
      } else {
        return 'avatar_blond_guy'
      }
    })

    const personaName = computed( () => store.state.personaName )

    const personaDbId = computed( () => store.state.savedPersonaId )
    const savedPersonaUrl = computed( () => {
      if(personaDbId.value) {
        return window.location.origin + '/persona-overview/' + personaDbId.value
      } else {
        return '';
      }
    })

    /************************/
    /* Generate Persona PDF */
    /************************/
    const avatarImgUrl = computed( () => {
      if(store.state.avatar) {
        return `/gfx/avatars/${store.state.avatar}.png`
      } else {
        return '/gfx/avatars/avatar_blond_guy.png'
      }
    })

    const personaNamePdf = computed( () => {
      if (store.state.personaName.length > 0) {
        return store.state.personaName
      } else {
        return "Persona"
      }
    })

    const jobTitle = computed( () => store.state.jobTitle )
    const age = computed( () => store.state.personaAge )
    const levelOfSchool = computed( () => store.state.levelOfSchool )
    const industry = computed( () => {
      if(store.state.industry){
        return store.state.industry
      } else {
        return '';
      }
    })
    const sizeOfOrganization = computed( () => store.state.sizeOfOrganization )
    const socialNetworks = computed( () => store.state.personaSocialNetwork )
    const personaCommunicate = computed( () => store.state.personaCommunicate )
    const personaTools = computed( () => store.state.personaTools )
    const jobResponsibilities = computed( () => store.state.jobResponsibilities )
    const jobMeasured = computed( () => store.state.jobMeasured )
    const whoReportTo = computed( () => store.state.whoReportTo )
    const goalsOrObjectives = computed( () => store.state.goalsOrObjectives )
    const howGainInformationForJob = computed( () => store.state.howGainInformationForJob )
    const personaBiggestChallenges = computed( () => store.state.personaBiggestChallenges )

    async function imgToBase64Async(url) {
      const base64 = new Promise((resolve) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
          var reader = new FileReader();
          reader.onloadend = function() {
            resolve(reader.result);
          }
          reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
      })

      return await base64
    }

    async function generatePdf() {

      const startLeft = 15;
      const avatarWidth = 30;
      const alignUnderAvatar = avatarWidth / 2 + startLeft
      const infoMaxWidth = avatarWidth + 2 * startLeft
      const additionalInfoStartLeft = infoMaxWidth + 5
      const additionalInfoMaxWidth = 210 - additionalInfoStartLeft - startLeft

      const pdfDoc = new jspdf('p', 'mm', 'a4')

      // Name and title
      pdfDoc.setFontSize(20)
      pdfDoc.text(personaNamePdf.value, 105, 20, {
        align: 'center',
      });
      pdfDoc.setFontSize(10)
      pdfDoc.text(jobTitle.value, 105, 26, {
        align: 'center',
      });

      // Add avatar image
      const avatarImageData = await imgToBase64Async(avatarImgUrl.value)
      pdfDoc.addImage(avatarImageData, 'PNG', startLeft, 30, avatarWidth, avatarWidth)

      // Age
      pdfDoc.setFontSize(10)
      pdfDoc.text('Alder', alignUnderAvatar, 66, {
        align: 'center',
      });
      pdfDoc.setFontSize(8)
      pdfDoc.text(age.value, alignUnderAvatar, 72, {
        align: 'center',
        maxWidth: infoMaxWidth
      });

      // Level of Education
      pdfDoc.setFontSize(10)
      pdfDoc.text('Højeste uddannelse', alignUnderAvatar, 88, {
        align: 'center',
      });
      pdfDoc.setFontSize(8)
      pdfDoc.text(levelOfSchool.value, alignUnderAvatar, 94, {
        align: 'center',
        maxWidth: infoMaxWidth
      });

      // Industry
      pdfDoc.setFontSize(10)
      pdfDoc.text('Branche', alignUnderAvatar, 120, {
        align: 'center',
      });
      pdfDoc.setFontSize(8)
      pdfDoc.text(industry.value, alignUnderAvatar, 126, {
        align: 'center',
        maxWidth: infoMaxWidth
      });

      // Organization Size
      pdfDoc.setFontSize(10)
      pdfDoc.text('Størrelsen af deres organisation', alignUnderAvatar, 152, {
        align: 'center',
      });
      pdfDoc.setFontSize(8)
      pdfDoc.text(sizeOfOrganization.value, alignUnderAvatar, 158, {
        align: 'center',
        maxWidth: infoMaxWidth
      });

      // Social Network
      pdfDoc.setFontSize(10)
      pdfDoc.text('Sociale netværk', alignUnderAvatar, 184, {
        align: 'center',
      });

      if (socialNetworks.value) {

        const imageSocilaNetworkData = await imgToBase64Async('/gfx/social-icons/social-networks.png')
        pdfDoc.addImage(imageSocilaNetworkData, 'PNG', 13, 190, 34, 20)

        for (const network of socialNetworks.value) {
          const socialIcon = network.name.toLowerCase()
          const iconWidth = 10
          const iconSpace = 2
          let iconStartPos = {x: startLeft, y: 190}

          switch (network.name) {
            case 'Facebook':
              iconStartPos = {x: alignUnderAvatar - 1.5 * iconWidth - iconSpace, y: 190}
              break;
            case 'Instagram':
              iconStartPos = {x: alignUnderAvatar - 0.5 * iconWidth, y: 190}
              break;
            case 'Twitter':
              iconStartPos = {x: alignUnderAvatar + 0.5 * iconWidth + iconSpace, y: 190}
              break;
            case 'LinkedIn':
              iconStartPos = {x: alignUnderAvatar - iconWidth - iconSpace, y: 200}
              break;
            case 'Pinterest':
              iconStartPos = {x: alignUnderAvatar + iconSpace, y: 200}
              break;
          }

          const imageData = await imgToBase64Async(`/gfx/social-icons/${socialIcon}.png`)
          pdfDoc.addImage(imageData, 'PNG', iconStartPos.x, iconStartPos.y, iconWidth, iconWidth)
        }
      }

      // Additional Info
      // Preferred Method of Communication
      pdfDoc.setFontSize(10)
      pdfDoc.text('Foretrukne måde at kommunikere på ', additionalInfoStartLeft, 36, {
        align: 'left',
      });
      pdfDoc.setFontSize(8)

      if(personaCommunicate.value) {
        let comMethodeText = "";
        let methodeIndex = 1

        for (let comMethod of personaCommunicate.value) {
          comMethodeText += comMethod.name
          if (methodeIndex < personaCommunicate.value.length) {
            comMethodeText += ', '
          } else {
            comMethodeText += '.'
          }
          methodeIndex++
        }

        pdfDoc.text(comMethodeText, additionalInfoStartLeft, 42, {
          align: 'left',
          maxWidth: additionalInfoMaxWidth
        });
      }

      // Tools They Need to Do Their Job
      pdfDoc.setFontSize(10)
      pdfDoc.text('Værktøjer de har brug for til at udføre deres job', additionalInfoStartLeft, 52, {
        align: 'left',
      });

      pdfDoc.setFontSize(8)
      if(personaTools.value){
        let toolText = "";
        let toolIndex = 1

        for(let tool of personaTools.value) {
          toolText += tool
          if(toolIndex < personaTools.value.length){
            toolText += ', '
          } else {
            toolText += '.'
          }
          toolIndex++
        }

        pdfDoc.text(toolText, additionalInfoStartLeft, 58, {
          align: 'left',
          maxWidth: additionalInfoMaxWidth
        });
      }

      // Job Responsibilities
      pdfDoc.setFontSize(10)
      pdfDoc.text('Jobansvar', additionalInfoStartLeft, 78, {
        align: 'left',
      });
      pdfDoc.setFontSize(8)
      pdfDoc.text(jobResponsibilities.value, additionalInfoStartLeft, 84, {
        align: 'left',
        maxWidth: additionalInfoMaxWidth
      });

      // Their Job Is Measured By
      pdfDoc.setFontSize(10)
      pdfDoc.text('Deres job bliver målt på', additionalInfoStartLeft, 100, {
        align: 'left',
      });
      pdfDoc.setFontSize(8)
      pdfDoc.text(jobMeasured.value, additionalInfoStartLeft, 106, {
        align: 'left',
        maxWidth: additionalInfoMaxWidth
      });

      // Reports To
      pdfDoc.setFontSize(10)
      pdfDoc.text('Rapporterer til', additionalInfoStartLeft, 122, {
        align: 'left',
      });
      pdfDoc.setFontSize(8)
      pdfDoc.text(whoReportTo.value, additionalInfoStartLeft, 128, {
        align: 'left',
        maxWidth: additionalInfoMaxWidth
      });

      // Goals or Objectives
      pdfDoc.setFontSize(10)
      pdfDoc.text('Mål eller fokus', additionalInfoStartLeft, 144, {
        align: 'left',
      });
      pdfDoc.setFontSize(8)
      pdfDoc.text(goalsOrObjectives.value, additionalInfoStartLeft, 150, {
        align: 'left',
        maxWidth: additionalInfoMaxWidth
      });

      // They Gain Information By
      pdfDoc.setFontSize(10)
      pdfDoc.text('Information/nyheder/viden til deres job', additionalInfoStartLeft, 166, {
        align: 'left',
      });
      pdfDoc.setFontSize(8)
      pdfDoc.text(howGainInformationForJob.value, additionalInfoStartLeft, 172, {
        align: 'left',
        maxWidth: additionalInfoMaxWidth
      });

      // Biggest Challenges
      pdfDoc.setFontSize(10)
      pdfDoc.text('Største udfordringer', additionalInfoStartLeft, 188, {
        align: 'left',
      });
      pdfDoc.setFontSize(8)

      if(personaBiggestChallenges.value) {
        let challengesText = "";
        let challengesIndex = 1

        for (let challenge of personaBiggestChallenges.value) {
          challengesText += challenge
          if (challengesIndex < personaBiggestChallenges.value.length) {
            challengesText += ', '
          } else {
            challengesText += '.'
          }
          challengesIndex++
        }

        pdfDoc.text(challengesText, additionalInfoStartLeft, 194, {
          align: 'left',
          maxWidth: additionalInfoMaxWidth
        });
      }

      // Save PDF file
      pdfDoc.save(`${personaNamePdf.value}.pdf`)

      /************************/
    }

    return {
      inputPersonaUrl,
      avatarImg,
      personaName,
      savedPersonaUrl,
      copyPersonaUrl,
      generatePdf
    }
  }
}
</script>

<style scoped>
.saved_persona_header{
  text-align: center;
}
.saved_persona_header img{
  max-width: 200px;
}

.get_persona_row{
  display: flex;
  margin-bottom: 220px;
}

.get_persona_row .get_persona_col{
  flex: 0 0 50%;
  max-width: 50%;
}

.get_persona_row .get_persona_block{
  height: 100%;
}

.get_persona_row .get_persona_col:first-child .get_persona_block{
  padding-right: 10px;
}

.get_persona_row .get_persona_col:last-child .get_persona_block{
  padding-left: 10px;
}

.get_persona_row .get_persona_block >>> .p-card{
  height: 100%;
}
</style>